import { type Vector, add, mag, mean, mul, sub } from "@/util/geometry";
import { type JSX, createEffect, createSignal, onCleanup } from "solid-js";

interface AnimationState {
  /** last frame time in seconds */
  lastFrame: number;
  /** last plane spawn time in seconds */
  lastPlaneSpawn: number;
  /** if the main plane exists or nah */
  hasMainPlane: boolean;

  planes: Array<{
    /** position in pixels */
    position: Vector;
    /** velocity in pixels per second */
    velocity: Vector;
    /** if true, plane is removed */
    died: number | null;
    /** if true, this plane is the main character (represents the user) */
    main: boolean;

    activity: string | null;
    activated: number | null;

    trail: { points: Vector[]; updated: number };
  }>;
}

const activities = [
  "stumbled into underground poetry slam",
  "joined pickup volleyball game on beach",
  "hopped on friend's motorcycle for city ride",
  "discovered farmers market, fresh local produce",
  "followed live music to street fair, danced",
  "explored new hiking trail with strangers",
  "joined impromptu frisbee game in park",
  "wandered into cozy bookshop, new author",
  "stumbled upon pop-up art gallery",
  "outdoor yoga class in park",
  "lingered over coffee at quaint cafe",
  "joined locals for pickup soccer game",
  "explored new neighborhood, hidden gem restaurant",
  "outdoor concert, danced under stars",
  "discovered charming flea market, unique finds",
  "joined group cyclists, countryside ride",
  "explored nature reserve, great outdoors",
  "stumbled upon lively street festival",
  "gathered with artists and creatives",
  "cozy jazz club, smooth melodies",
  "joined game night at friend's place",
  "discovered hole-in-the-wall music venue",
  "went on spontaneous road trip",
  "stumbled into outdoor movie screening",
  "explored new city on foot",
  "joined pickup basketball at local court",
  "discovered hidden beach, swam in sea",
  "wandered into comedy club, laughed night away",
  "joined strangers for sunset picnic",
  "discovered underground club, danced till dawn",
  "explored abandoned building, urban exploring",
  "stumbled into outdoor art installation",
  "joined drum circle in park",
  "went on late night diner adventure",
  "discovered hidden gem bakery, life-changing pastry",
  "caught impromptu street performance",
  "wandered high line park",
  "explored touristy pier",
  "rode cable cars",
  "discovered hidden residential garden",
  "people-watched at town square",
  "window-shopped famous shopping district",
  "caught live blues at club",
  "dined at hole-in-wall mexican joint",
  "wandered iconic neighborhood",
];

export function Planes(props: JSX.CanvasHTMLAttributes<HTMLCanvasElement>) {
  let canvas!: HTMLCanvasElement;
  const animationState: AnimationState = {
    lastFrame: 0,
    lastPlaneSpawn: 0,
    hasMainPlane: false,
    planes: [],
  };

  const [canvasSize, setCanvasSize] = createSignal({ width: 0, height: 0 });

  createEffect(() => {
    setCanvasSize({
      width: canvas.clientWidth,
      height: canvas.clientHeight,
    });

    const observer = new ResizeObserver(() => {
      setCanvasSize({
        width: canvas.clientWidth,
        height: canvas.clientHeight,
      });
    });
    observer.observe(canvas);

    onCleanup(() => {
      observer.unobserve(canvas);
    });
  });

  createEffect(() => {
    canvas.width = canvasSize().width;
    canvas.height = canvasSize().height;

    let animating = true;
    const planeImg = new Image(48, 48);
    planeImg.src = "/icons/navigation-pointer-02.svg";

    const onAnimationFrame: FrameRequestCallback = (timestampMillis) => {
      // console.log("onAnimationFrame", timestampMillis);
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      const timestamp = timestampMillis / 1000;
      if (animationState.lastFrame === 0) animationState.lastFrame = timestamp;

      const { width, height } = canvasSize();
      const radius = Math.sqrt(width * width + height * height);
      const delta = timestamp - animationState.lastFrame;

      const maxPlanes = (width * height) / 50_000;

      // spawn a plane every 1s
      while (
        animationState.planes.length < maxPlanes &&
        (timestamp - animationState.lastPlaneSpawn > 1.0 || animationState.planes.length < 10)
      ) {
        const incomingAngle = Math.random() * 2 * Math.PI;
        const movementAngle = incomingAngle + (Math.random() - 0.5) * Math.PI * 0.5;
        const speed = 50;

        const position: Vector = [
          width / 2 + (radius / 2) * Math.cos(incomingAngle),
          height / 2 + (radius / 2) * Math.sin(incomingAngle),
        ];

        const main = !animationState.hasMainPlane;

        animationState.planes.push({
          position,
          velocity: [-speed * Math.cos(movementAngle), -speed * Math.sin(movementAngle)],
          main,
          died: null,
          activated: null,
          activity: null,
          trail: { points: [position], updated: timestamp },
        });

        animationState.lastPlaneSpawn = timestamp;
        animationState.hasMainPlane ||= main;
      }

      // update the positions of all planes
      for (const plane of animationState.planes) {
        if (plane.died !== null) continue;

        if (timestamp - plane.trail.updated >= 0.25) {
          plane.trail.points.push([plane.position[0], plane.position[1]]);
          plane.trail.points = plane.trail.points.slice(-20);
          plane.trail.updated = timestamp;
        }

        plane.position = add(plane.position, mul(plane.velocity, delta));

        const nearbyDistanceThreshold = 200;
        const nearbyDistanceThresholdSq = nearbyDistanceThreshold * nearbyDistanceThreshold;
        const neighborDistanceThreshold = 50;
        const neighborDistanceThresholdSq = neighborDistanceThreshold * neighborDistanceThreshold;

        const nearbyPlanes = animationState.planes.filter((otherPlane) => {
          const distanceX = plane.position[0] - otherPlane.position[0];
          const distanceY = plane.position[1] - otherPlane.position[1];
          const distanceSq = Math.max(distanceX * distanceX + distanceY * distanceY, 1);

          return nearbyDistanceThresholdSq >= distanceSq;
        });

        const neighborPlanes = animationState.planes.filter((otherPlane) => {
          const distanceX = plane.position[0] - otherPlane.position[0];
          const distanceY = plane.position[1] - otherPlane.position[1];
          const distanceSq = Math.max(distanceX * distanceX + distanceY * distanceY, 1);

          return neighborDistanceThresholdSq >= distanceSq;
        });

        const nearbyCentroid = mean(...nearbyPlanes.map((p) => p.position));
        const nearbyVelocity = mean(...nearbyPlanes.map((p) => p.velocity));
        const neighborDelta = add(...neighborPlanes.map((p) => sub(plane.position, p.position)));

        const alignmentFactor = 0.7;
        const cohesionFactor = 0.4;
        const separationFactor = 0.7;

        plane.velocity = add(plane.velocity, mul(nearbyVelocity, alignmentFactor * delta));
        plane.velocity = add(
          plane.velocity,
          mul(sub(nearbyCentroid, plane.position), cohesionFactor * delta),
        );
        plane.velocity = add(plane.velocity, mul(neighborDelta, separationFactor * delta));

        if (plane.main && !plane.activated) {
          if (nearbyPlanes.length >= 5 || neighborPlanes.length >= 2) {
            plane.activated = timestamp;
            plane.activity = activities[(Math.random() * activities.length) | 0];
          }
        }

        const speed = mag(plane.velocity);
        if (speed > 75) plane.velocity = mul(plane.velocity, 75 / speed);
        if (speed < 35) plane.velocity = mul(plane.velocity, 35 / speed);

        const buffer = 150;

        if (
          (plane.position[0] < -buffer && plane.velocity[0] < 0) ||
          (plane.position[0] > width + buffer && plane.velocity[0] > 0) ||
          (plane.position[1] < -buffer && plane.velocity[1] < 0) ||
          (plane.position[1] > height + buffer && plane.velocity[1] > 0)
        ) {
          plane.died = timestamp;

          if (plane.main) {
            animationState.hasMainPlane = false;
          }
        }
      }

      // remove dead planes
      animationState.planes = animationState.planes.filter(
        (p) => p.died === null || timestamp - p.died < 1.0,
      );

      ctx.reset();
      for (const plane of animationState.planes) {
        if (!plane.main) {
          ctx.globalAlpha = 0.2;
        }

        if (plane.died) {
          ctx.globalAlpha *= 1 - (timestamp - plane.died);
        }

        const trail = [...plane.trail.points, plane.position];
        trail.reverse();

        const trailLength = 20;

        ctx.setLineDash([5, 5]);
        ctx.lineWidth = 1;

        for (let index = 0; index < trail.length - 1 && index < trailLength; index++) {
          const [end, start] = trail.slice(index, index + 2);

          const opacity = Math.min(1, Math.max(1 - index / trailLength, 0));

          ctx.beginPath();
          ctx.moveTo(start[0], start[1]);
          ctx.lineTo(end[0], end[1]);
          ctx.strokeStyle = `rgba(0, 0, 0, ${opacity})`;
          ctx.stroke();
        }

        ctx.setLineDash([]);

        const angle = Math.atan2(plane.velocity[1], plane.velocity[0]);
        ctx.translate(plane.position[0], plane.position[1]);
        ctx.rotate(angle + Math.PI / 2);
        ctx.translate(-12, -12);

        ctx.drawImage(planeImg, 0, 0);
        ctx.globalAlpha = 1.0;

        ctx.resetTransform();

        if (plane.main && plane.activated && plane.activity) {
          const opacity = (timestamp - plane.activated) / 3.0;

          ctx.textAlign = "center";
          ctx.fillStyle = `rgba(0, 0, 0, ${opacity})`;
          ctx.font = "bold 1rem 'Inter'";
          ctx.fillText(plane.activity, plane.position[0], plane.position[1] + 40);
        }
      }

      animationState.lastFrame = timestamp;

      if (animating) {
        requestAnimationFrame(onAnimationFrame);
      } else {
        console.log("animation cancelled");
      }
    };

    requestAnimationFrame(onAnimationFrame);

    onCleanup(() => {
      animating = false;
    });
  });

  return <canvas ref={canvas} {...props} />;
}
